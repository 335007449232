<template>
  <div>
    <el-image
      class="banner"
      :src="require('@as/serve/brand/banner.png')"
    ></el-image>
    <div class="main">
      <el-menu :default-active="2" class="menu">
        <el-menu-item
          :index="index + 1"
          v-for="(item, index) in menuList"
          v-bind:key="item"
          >{{ item }}</el-menu-item
        >
      </el-menu>
      <div class="content">
        <div class="title">- 关于我们 -</div>
        <div class="detail">
          <p>
            京东方科技集团股份有限公司（BOE）创立于1993年4月，是一家为信息交互和人类健康提供智慧端口产品和专业服务的物联网公司。核心事业包括端口器件、智慧物联和智慧医工三大领域。
          </p>
          <p>
            端口器件事业包括显示与传感器件、传感器及解决方案。作为全球半导体显示产业龙头企业，BOE（京东方）带领中国显示产业实现了从无到有、从有到大、从大到强。目前全球有超过四分之一的显示屏来自BOE（京东方），其超高清、柔性、微显示等解决方案已广泛应用于国内外知名品牌。
          </p>
          <p>
            智慧物联事业包括智造服务、IoT解决方案和数字艺术，可为智慧零售、智慧金融、数字艺术、商务办公、智慧家居、智慧交通、智慧政教、智慧能源等细分领域提供物联网整体解决方案。在数字艺术领域，BOE（京东方）推出数字艺术物联网产品——BOE画屏，实现科技与艺术完美结合；在商超零售等领域，BOE（京东方）提供价格管理、货架管理、客户行为分析等物联网新零售解决方案，实现零售O+O无缝衔接。
          </p>
          <p>
            智慧医工事业包括移动健康和健康服务。BOE（京东方）已推出移动健康管理平台，通过智能终端进行健康数据检测，基于人工智能和大数据算法，为用户提供生命体征数据监测解读、AI疾病风险预测、专家健康课程以及在线问诊、体检挂号等就医服务，让用户足不出户即可享受个性化的家庭健康管理服务。健康服务创新性的融合医疗、信息、人工智能和细胞工程等技术，聚焦数字医院、数字人体、再生医学、解决方案领域，为客户提供全方位、全生命周期的健康管理方案。
          </p>
          <p>
            2019年，BOE（京东方）新增专利申请量9657件，其中发明专利超90%，累计可使用专利超7万件，覆盖美国、欧洲、日本、韩国等国家和地区。美国专利服务机构IFI
            Claims发布数据显示，2019年BOE（京东方）全球排名跃升至第13位，美国专利授权量达2177件，同比增长33%，连续4年在IFI
            TOP50榜单中实现排名与美国专利授权量双增长；世界知识产权组织（WIPO）发布2019年全球国际专利申请（PCT）情况，BOE（京东方）以1864件PCT申请位列全球第六。
          </p>
          <p>
            BOE（京东方）在北京、合肥、成都、重庆、福州、绵阳、武汉、昆明、苏州、鄂尔多斯、固安等地拥有多个制造基地，子公司遍布美国、德国、英国、法国、瑞士、日本、韩国、新加坡、印度、俄罗斯、巴西、阿联酋等19个国家和地区，服务体系覆盖欧、美、亚、非等全球主要地区。
          </p>
          <p>
            智慧医工事业包括移动健康和健康服务。BOE（京东方）已推出移动健康管理平台，通过智能终端进行健康数据检测，基于人工智能和大数据算法，为用户提供生命体征数据监测解读、AI疾病风险预测、专家健康课程以及在线问诊、体检挂号等就医服务，让用户足不出户即可享受个性化的家庭健康管理服务。健康服务创新性的融合医疗、信息、人工智能和细胞工程等技术，聚焦数字医院、数字人体、再生医学、解决方案领域，为客户提供全方位、全生命周期的健康管理方案。
          </p>
          <p>
            2019年，BOE（京东方）新增专利申请量9657件，其中发明专利超90%，累计可使用专利超7万件，覆盖美国、欧洲、日本、韩国等国家和地区。美国专利服务机构IFI
            Claims发布数据显示，2019年BOE（京东方）全球排名跃升至第13位，美国专利授权量达2177件，同比增长33%，连续4年在IFI
            TOP50榜单中实现排名与美国专利授权量双增长；世界知识产权组织（WIPO）发布2019年全球国际专利申请（PCT）情况，BOE（京东方）以1864件PCT申请位列全球第六。
            BOE（京东方）在北京、合肥、成都、重庆、福州、绵阳、武汉、昆明、苏州、鄂尔多斯、固安等地拥有多个制造基地，子公司遍布美国、德国、英国、法国、瑞士、日本、韩国、新加坡、印度、俄罗斯、巴西、阿联酋等19个国家和地区，服务体系覆盖欧、美、亚、非等全球主要地区。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        '关于我们',
        ' 加入我们',
        ' 商业合作',
        ' 友情链接',
        ' 隐私条款',
        ' 用户协议',
        ' 免责声明',
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.banner {
  margin-top: -20px;
  height: 400px;
}
.main {
  margin: -280px auto 50px;
  width: 1200px;
  display: flex;

  .menu {
    padding-top: 35px;
    flex-shrink: 0;
    width: 200px;
    & /deep/ .el-menu-item {
      padding-left: 40px !important;
      height: 50px;
      line-height: 50px;
      &:hover {
        color: #fff;
        background: rgba(74, 150, 165, 0.7);
      }
    }
    & /deep/ .el-menu-item:focus {
      background: none;
    }
    & /deep/ .el-menu-item.is-active {
      position: relative;
      color: #4a96a5;
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        width: 4px;
        height: 24px;
        background: #4a96a5;
        transform: translateY(-50%);
      }
    }
  }
  .content {
    z-index: 2;
    padding: 54px 50px;
    border-left: 1px solid #dedede;
    background: #fff;
    .title {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .detail {
      margin-top: 48px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      p {
        margin-bottom: 32px;
        text-indent: 2em;
      }
    }
  }
}
</style>